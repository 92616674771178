/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from '@material-ui/core'

// import AccessAlarmIcon  from '@material-ui/icons/AccessAlarm';
import {
  FaSignOutAlt,
  FaUserCircle,
  FaSignInAlt,
  FaUser,
  FaQuestionCircle,
  FaCheckSquare,
  FaExchangeAlt,
  FaPoll,
  FaRegEdit,
} from 'react-icons/fa'
// import {GrTransaction} from 'react-icons/gr';
import { AiFillSetting } from 'react-icons/ai'
import { MdAttachMoney } from 'react-icons/md'
import { PieChart as PieChartIcon } from 'react-feather'
import { RiDashboardFill } from 'react-icons/ri'
import NavItem from './NavItem'
const sections = [
  {
    subheader: 'Explore',
    items: [
      {
        title: 'All',
        icon: 'icon-book',
        // icon: FaUserCircle,
        href: '/category-list?All',
      },
      {
        title: 'Art',
        icon: 'icon-palette',
        // icon: FaUserCircle,
        href: '/category-list?Art',
      },
      {
        title: 'Photography',
        icon: 'icon-camera',
        href: '/category-list?Photography',
      },
      {
        title: 'Games',
        icon: 'icon-games',
        href: '/category-list?Games',
      },
      {
        title: 'Metaverses',
        icon: 'icon-net',
        href: '/category-list?Metaverses',
      },
      {
        title: 'Music',
        icon: 'icon-music',
        href: '/category-list?Music',
      },
      // {
      //   title: 'Domains',
      //   icon: 'icon-web',
      //   href: '/category-list?Domains',
      // },
      {
        title: 'DeFi',
        icon: 'icon-defi',
        href: '/category-list?DeFi',
      },
      {
        title: 'Memes',
        icon: 'icon-baby',
        href: '/category-list?Memes',
      },
      {
        title: 'Punks',
        icon: 'icon-punks',
        href: '/category-list?Punks',
      },
      // {
      //   title: "NSFW",
      //   icon: "icon-memes",
      //   href: "/category-list?NSFW",
      // },
    ],
  },
  {
    subheader: 'More',
    items: [
      {
        title: 'Top Sellers',
        icon: 'icon-top',
        href: '/hot-data?topSellers',
      },
      // {
      //   title: 'Live Auctions',
      //   icon: 'icon-compass',
      //   href: '/hot-data?liveAcuctions',
      // },
      {
        title: 'Hot Bids',
        icon: 'icon-flame',
        href: '/hot-data?hotBids',
      },
      {
        title: 'Hot Collections',
        icon: 'icon-hot',
        href: '/hot-data?hotCollection',
      },
    ],
  },
]

function renderNavItems({ items, ...props }) {
  return (
    <List disablePadding style={{ curser: 'pointer' }}>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...props }),
        [],
      )}
    </List>
  )
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0,
  isNested = false,
}) {
  const key = item.title + depth

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          isNested: true,
        })}
      </NavItem>,
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={<div style={{ textAlign: 'left' }}>{item.title}</div>}
      />,
    )
  }

  return acc
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: 'linear-gradient(152.97deg, #fff3 0%, #fff0 100%)',
    backdropFilter: ' blur(42px)',
    // background: "#f4f4f4",
  },
  desktopDrawer: {
    width: 256,
    position: 'absolute',
    top: 138,
    height: 'auto',
    zIndex: 1,
    background:
      'linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
    overflow: 'hidden',
    backdropFilter: 'blur(42px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: 'pointer',
    marginRight: 5,
  },
}))

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles()
  const location = useLocation()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose(false)
      openMobile(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const content = (
    <Box display="flex" flexDirection="column" className="siemenu-mainbox">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          {sections.map((section, i) => (
            <List
              className="bgcolorleftside"
              key={`menu${i}`}
              subheader={
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{
                    paddingLeft: '15px',
                    fontSize: '13px',
                    fontWeight: '300',
                  }}
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          posittion="absolute"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
          posittion="absolute"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
