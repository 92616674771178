import React, { createContext, useEffect, useState } from "react";
import { injected, SUPPORTED_WALLETS } from "src/connectors";
import { useWeb3React, useSWR } from "@web3-react/core";
import axios from "axios";
import apiConfig, { socketURL } from "src/config/ApiConfig";
import Web3 from "web3";
import moment from "moment";
import { getContract, getWeb3ContractObject } from "src/utils";
import DaoABI from "src/constants/ABI/DaoABI.json";
import {
  FungyAddress,
  EngineAddress,
  RPC_URL,
  daoContract,
} from "src/constants";
import Engine from "src/constants/ABI/Engine.json";
export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [successMSG, setSuccessMSG] = useState("");
  const [allCollection, setAllCollection] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [allNftList, setAllNftList] = useState([]);
  const [allUserList, setAllUserList] = useState([]);
  const [auctionList, setAuctionList] = useState([]);
  const [allBidList, setAllBidList] = useState([]);
  const [hotBidList, setHotBidList] = useState([]);
  const [liveEthPrice, setLiveEthPrice] = useState();
  const [serviceFee, setServiceFee] = useState(0);
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [hotCollectionList, setHotCollectionList] = useState([]);
  const [allListPageNumber, setAllListPageNumber] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [promotedNftList, setPromotedNftList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [errorPop, setErrorPop] = useState(false);
  const [ownerAccAddress, setOwnerAccAddress] = useState("");
  const [followingList, setFollowingList] = useState([]);
  const [collectionCardData, setCollectioCardData] = useState([]);
  const [hotBidsData, setHotBidsData] = useState([]);
  const [topSellerData, setTopSellerData] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notificationLodaing, setNotifyLoading] = useState(false);
  const [ruleDataList, setRuleDataList] = useState([]);
  const [proposalDataList, setProposalDataList] = useState([]);
  const [isLoadingProposals, setIsloadingProposals] = useState(false);
  const [isLoadingRules, setILoadingRule] = useState(false);
  const [adminAccount, setAdminAccount] = useState("");
  const connectToWallet = (data) => {
    if (data) {
      const connector = data.data.connector;
      window.sessionStorage.removeItem("walletName");
      window.sessionStorage.setItem("walletName", data.name);
      setErrorMsg("");
      setSuccessMSG("");
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }
      activate(connector, undefined, true).catch((error) => {
        if (error) {
          console.log("error", error.message);
          setErrorMsg(error.message + " Please install " + data.name);
          activate(connector);
          setIsLoading(false);
          setErrorPop(true);
        }
      });
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (account) {
      connectWalletHandler(account);
    } else {
      setIsLogin(false);
      setUserData();
    }
  }, [account]);

  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem("walletName")
      );
      connectToWallet(selectectWalletDetails[0]);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account, library]);
  const getDaoContractDatahandler = async () => {
    try {
      setILoadingRule(true);
      const contractObj = await getWeb3ContractObject(
        DaoABI,
        daoContract,
        RPC_URL
      );
      console.log("contractObj-----", contractObj);

      const adminAccountCheck = await contractObj.methods.owner().call();
      console.log("adminAccount", adminAccountCheck);
      setAdminAccount(adminAccountCheck);
      const ruleLength = await contractObj.methods._rulescount().call();
      let obj = [];
      for (let i = 0; i < ruleLength; i++) {
        const ruleID = await contractObj.methods._rules(i).call();
        const rulesInfo = await contractObj.methods.rulesInfo(ruleID).call();
        let dataProp = {
          committeeID: rulesInfo.committeeID,
          minPercentageOfLikes: rulesInfo.minPercentageOfLikes,
          minutesSetProposalPeriod: rulesInfo.minutesSetProposalPeriod,
          minMinutesDebatePeriod: rulesInfo.minMinutesDebatePeriod,
          feesRewardInflationRate: rulesInfo.feesRewardInflationRate,
          tokenPriceInflationRate: rulesInfo.tokenPriceInflationRate,
          defaultMinutesFundingPeriod: rulesInfo.defaultMinutesFundingPeriod,
          ruleID: ruleID,
        };
        obj.push(dataProp);
      }
      console.log("obj====>>>", obj);
      setRuleDataList(obj);
      setILoadingRule(false);
    } catch (err) {
      console.log(err);
      setILoadingRule(false);
    }
  };
  const getDaoProposalDataHandler = async () => {
    try {
      setIsloadingProposals(true);
      const contractObj = await getWeb3ContractObject(
        DaoABI,
        daoContract,
        RPC_URL
      );
      console.log("contractObj-----", contractObj);
      const ruleLength = await contractObj.methods._proposalCount().call();
      console.log("countProposal*******", ruleLength);
      let obj = [];
      for (let i = 0; i < ruleLength; i++) {
        const ruleID = await contractObj.methods._proposal(i).call();
        const rulesInfo = await contractObj.methods
          .proposaldetail(ruleID)
          .call();
        const proposalFinalDecision = await contractObj.methods
          .proposalfinal(ruleID)
          .call();

        let dataProp = {
          proposalID: rulesInfo.proposalID,
          contractor: rulesInfo.contractor,
          moderator: rulesInfo.moderator,
          proposalStart: rulesInfo.proposalStart,
          proposalEnd: rulesInfo.proposalEnd,
          status: rulesInfo.status,
          discription: rulesInfo.discription,
          downvote: proposalFinalDecision.downvote,
          upvote: proposalFinalDecision.upvote,
        };
        obj.push(dataProp);
      }
      console.log("obj==setProposalDataList==>>>", obj);
      setProposalDataList(obj);
      setIsloadingProposals(false);
    } catch (err) {
      console.log(err);
      setIsloadingProposals(false);
    }
  };
  const getUserbalce = async () => {
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  const connectWalletHandler = async (walletAddress) => {
    try {
      const res = await axios.post(apiConfig.connectWallet, {
        walletAddress,
      });
      if (res.data.statusCode === 200) {
        getProfileHandler(res.data.result.token);
        setTokenSession(res.data.result.token);
        setSuccessMSG(res.data.responseMessage);
      } else {
        deactivate();
        setIsLogin(false);
        setUserData();
        setIsLoading(false);
      }
    } catch (error) {
      deactivate();
      setIsLogin(false);
      console.log("ERROR", error);
      setIsLoading(false);
    }
  };

  const getProfileHandler = async (token) => {
    try {
      const res = await axios.get(apiConfig.profile, {
        headers: {
          token,
        },
      });
      setIsLoading(false);
      if (res.data.statusCode === 200) {
        // console.log('profile=====>>>>>', res.data.result)
        setUserData(res.data.result);
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    } catch (error) {
      setIsLogin(false);
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line
  useEffect(() => {
    getDaoContractDatahandler();
    getDaoProposalDataHandler();
  }, []);

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  const getPlaceOrderList = async (isLoadmore) => {
    let page = isLoadmore ? allListPageNumber + 1 : allListPageNumber;
    setAllListPageNumber(page);
    let allList = [];
    for (let i = 1; i <= page; i++) {
      try {
        const res = await axios.get(apiConfig.allOrderList, {
          params: {
            page: i,
          },
        });
        if (res.data.statusCode === 200) {
          if (res.data.result.docs) {
            setMaxPages(res.data.result.pages);
            allList = await allList.concat(res.data.result.docs);

            const result = allList.filter(
              (data) => data.price || parseFloat(data.startPrice)
            );
            const acuList = result.filter((data) => {
              return (
                (data.orderType === "TIMED_AUCTION" ||
                  data.orderType === "AUCTION_FIXEDPRICE_BOTH") &&
                parseFloat(data.endTime) > moment().unix()
              );
            });
            setAllOrders(result);
            setAuctionList(acuList);
          } else {
            setAllOrders([]);
            setAuctionList([]);
          }
        } else {
          setAllOrders([]);
          setAuctionList([]);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const allNftListHandler = async (token) => {
    try {
      const res = await axios.get(apiConfig.allNftList);
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setAllNftList(res.data.result.docs);
        } else {
          setAllNftList([]);
        }
      } else {
        setAllNftList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getlistCollection = async () => {
    try {
      const res = await axios.get(apiConfig.listCollection);
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setAllCollection(res.data.result.docs);
        } else {
          setAllCollection([]);
        }
      } else {
        setAllCollection([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getallBidList = async () => {
    try {
      const res = await axios.get(apiConfig.allbidList);
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setAllBidList(res.data.result.docs);
        } else {
          setAllBidList([]);
        }
      } else {
        setAllBidList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const hotBidListHandler = async () => {
    try {
      const res = await axios.get(apiConfig.hotBidList);
      if (res.data.statusCode === 200) {
        setHotBidList(res.data.result);
      } else {
        setHotBidList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const gethotCollectionList = async () => {
    const userToken = window.sessionStorage.getItem("token");
    try {
      const res = await axios.get(apiConfig.recentCollectionList, {
        // headers: {
        //   token: userToken,
        // },
      });

      if (res.data.statusCode === 200) {
        // console.log('collectionCardDetails', res.data.result.docs)
        setCollectioCardData(res.data.result.docs);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  const getHotBidsAPIData = async () => {
    try {
      const res = await axios.get(apiConfig.hotBid, {});

      if (res.data.statusCode === 200) {
        setHotBidsData(res.data.result);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const topsellerCardData = async () => {
    try {
      axios({
        method: "GET",
        url: apiConfig.topSeller,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      }).then(async (res) => {
        if (res.data.statusCode === 200) {
          // console.log('******topSellerData******', res.data.result.docs)
          setTopSellerData(res.data.result.docs);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const [allCategory, setAllCategory] = useState([]);

  const adminFun = async () => {
    try {
      const contractObj = await getWeb3ContractObject(
        Engine,
        EngineAddress,
        RPC_URL
      );
      // console.log('contractOnj=====>>>', contractObj)
      const ownerAccount = await contractObj.methods.owner().call();
      console.log("ownerAccount", ownerAccount);
      setOwnerAccAddress(ownerAccount);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    adminFun();
  }, []);
  const getFollowingDetails = async (id, cancelTokenSource) => {
    try {
      const res = await axios.get(apiConfig.userFollowingCount + id, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.status === 200) {
        setFollowingList(res.data.result);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  // console.log('followingList====>>>>', followingList)
  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    updateData();
    getFollowingDetails(userData?._id, cancelTokenSource);
    // allNftListHandler()
    // getuserList()
    // getLiveEthPrice()
    gethotCollectionList();
    // getServiceFees()
    // promotedListHandelr()
    // getListCategory()
  }, [isUpdateData]);

  const updateData = () => {
    getPlaceOrderList();
    topsellerCardData();
    // getallBidList()
    getHotBidsAPIData();
    // promotedListHandelr()
  };
  useEffect(() => {
    const web = new WebSocket(socketURL);
    const accessToken = sessionStorage.getItem("token");
    if (accessToken && account) {
      setNotifyLoading(true);
      try {
        web.onopen = () => {
          const dataToSend = {
            option: "notification",
            token: accessToken,
          };
          web.send(JSON.stringify(dataToSend));
          web.onmessage = async (event) => {
            if (event.data !== "[object Promise]" && event.data !== "null") {
              let obj = JSON.parse(event.data);
              if (obj.data && obj.data.length > 0) {
                setNotificationList(obj.data);
                setUnreadCount(obj.unReadCount);
                setNotifyLoading(false);
              } else {
                setNotificationList([]);
                setUnreadCount(0);
                setNotifyLoading(false);
              }
            } else {
              setNotifyLoading(false);
            }
          };
        };
        return () => {
          setNotificationList();
          setUnreadCount(0);
          web.close();
        };
      } catch (err) {}
    }
  }, [isLogin]);

  let data = {
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectToWallet(data),
    isLogin,
    userData,
    errorMsg,
    successMSG,
    allCollection,
    allOrders,
    allNftList,
    allUserList,
    auctionList,
    allBidList,
    hotBidList,
    hotCollectionList,
    liveEthPrice,
    serviceFee,
    maxPages,
    allListPageNumber,
    yourWalletBalance,
    promotedNftList,
    isLoading,
    allCategory,
    errorPop,
    isLoadingRules,
    isLoadingProposals,
    setErrorPop,
    ownerAccAddress,
    followingList,
    collectionCardData,
    hotBidsData,
    topSellerData,
    notificationList,
    unreadCount,
    notificationLodaing,
    ruleDataList,
    proposalDataList,
    adminAccount,
    getFollowingDetails: (data) => getFollowingDetails(data),
    allNftListHandler: () => allNftListHandler(),
    setIsUpdateData: () => setIsUpdateData(!isUpdateData),
    getlistCollection: () => getlistCollection(),
    getPlaceOrderList: (data) => getPlaceOrderList(data),
    getallBidList: () => getallBidList(),
    getProfileHandler: (token) => getProfileHandler(token),
    hotBidListHandler: () => hotBidListHandler(),
    updateData: () => updateData(),
    getDaoProposalDataHandler: () => getDaoProposalDataHandler(),
    getDaoContractDatahandler: () => getDaoContractDatahandler(),
    logoutHandler: () => {
      setIsLogin(false);
      setUserData();
      setYourWalletBalance(0);
      deactivate();
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("walletName");
    },
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
