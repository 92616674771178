import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, IconButton, Hidden } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "../HomeLayout/TopBar";
import Footer from "../HomeLayout/Footer";
import MenuIcon from "@material-ui/icons/Menu";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#000",
    // display: 'flex',
    // height: '100%',
    // overflow: 'hidden',
    width: "100%",
  },
  // content: {
  //   minHeight: "600px",
  // },
  wrapper: {
    paddingTop: 80,
    paddingLeft: 0,
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 290,
      overflowX: "hidden",
    },
    // [theme.breakpoints.up("xs")]: {
    //   paddingLeft: 8,
    // },

    "@media (max-width: 767px)": {
      paddingLeft: 8,
    },
  },
  shade: {
    position: "absolute",
    left: "auto",
    top: "500px",
    right: "10%",
    bottom: "auto",
    transform: "rotate(45deg)",
    zIndex: " 1",
    width: " 300px",
    height: "300px",
    borderRadius: " 1000px",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135) 0%, rgb(39 121 124) 95.78%)",
    opacity: "0.55",
    // -webkit-filter: blur(100px);
    filter: "blur(100px)",
  },
  shade2: {
    position: "absolute",
    left: "auto",
    top: "10%",
    right: "10%",
    bottom: "auto",
    zIndex: " 1",
    width: " 300px",
    height: "300px",
    borderRadius: " 1000px",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(18 52 140 / 58%) 0%, rgb(18 52 140 / 55%) 95.78%)",
    opacity: "0.55",
    // -webkit-filter: blur(100px);
    filter: "blur(100px)",
  },
  shade3: {
    position: "fixed",
    left: "0%",
    top: "100px",
    right: "auto",
    bottom: "auto",
    transform: "rotate(0deg)",
    zIndex: " 0",
    width: " 250px",
    height: "550px",
    borderRadius: " 50%",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(14 42 62) 0%, rgb(28 72 103) 95.78%)",
    opacity: "0.8",
    filter: "blur(100px)",
  },
  shade4: {
    position: "fixed",
    left: "auto",
    bottom: "10%",
    right: "0",
    top: "auto",
    transform: "rotate(45deg)",
    // zIndex: " -1",
    width: " 250px",
    height: "350px",
    opacity: "0.8",
    borderRadius: " 1000px",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(108 17 117 / 58%) 0%, rgb(217 55 211 / 51%) 95.78%)",
    filter: "blur(100px)",
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      {/* <div className={classes.shade}></div>
      <div className={classes.shade2}></div> */}
      <div className={classes.shade3}></div>
      <div className={classes.shade4}></div>
      <TopBar />
      <Box className='textright'>
        <Box className='YourWrappingContainerClass'>
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />

          <div className={classes.wrapper}>
            <Hidden lgUp>
              <IconButton
                className='filterIcon'
                onClick={() => setMobileNavOpen(true)}
              >
                <MenuIcon
                  width='60px'
                  height='60px'
                  style={{ color: "#F6C73B", fontSize: "30px" }}
                />
                <span> Filter</span>
              </IconButton>
            </Hidden>

            <div className={classes.contentContainer}>
              <div className={classes.content} id='main-scroll'>
                {children}
              </div>
            </div>
          </div>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
