import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/item",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Search/Index")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About/AboutUs")),
  },
  {
    exact: true,
    path: "/collection",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Collection/")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/my-items",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyItems/index")),
  },
  {
    exact: true,
    path: "/proposals",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dao/ProposalListing")),
  },
  {
    exact: true,
    path: "/following",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Search/Following")),
  },
  {
    exact: true,
    path: "/hot-data",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Search/HotData")),
  },
  {
    exact: true,
    path: "/category-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Search/CategoryList")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/component/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/faq",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/HelpCenter/index")),
  },
  {
    exact: true,
    path: "/activity",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Activity/index")),
  },
  {
    exact: true,
    path: "/nft-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NftDetails/index")),
  },
  // {
  //   exact: true,
  //   path: "/create",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Create-NFT/Create")),
  // },
  {
    exact: true,
    path: "/create",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Create-NFT/CreateNFT")),
  },
  {
    exact: true,
    path: "/create-rule",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dao/CreateRule")),
  },
  {
    exact: true,
    path: "/create-proposals",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dao/CreateProposals")),
  },

  // {
  //   exact: true,
  //   path: "/resell-nft",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Create-NFT/ResellNFT")),
  // },
  {
    exact: true,
    path: "/user",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/UsersView/UsersView")),
  },
  {
    exact: true,
    path: "/profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  },
  {
    exact: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wallet/index")),
  },
  {
    exact: true,
    path: "/fungy-token",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FungyToken/Index")),
  },
  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FAQ")),
  },
  {
    exact: true,
    path: "/notification",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Notification")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
