// let baseurl = "https://node-opensea-v2.mobiloitte.com";
let baseurl = 'https://node-dao.mobiloitte.com'

let user = `${baseurl}/api/v1/user`
let newuser = `${baseurl}/api/v1`
let nft = `${baseurl}/api/v1/nft`
let collection = `${baseurl}/api/v1/collection`
let order = `${baseurl}/api/v1/order`
let activity = `${baseurl}/api/v1/activity`
let bid = `${baseurl}/api/v1/bid`
let notification = `${baseurl}/api/v1/notification`
export const socketURL = 'wss://node-dao.mobiloitte.com'
const apiConfig = {
  //USER
  connectWallet: `${user}/connectWallet`,
  profile: `${user}/profile`,
  updateProfile: `${user}/updateProfile`,
  recentCollectionList: `${newuser}/collection/recentCollectionList`,

  userOwendCount: `${user}/userOwendCount/`,
  userCreatedCount: `${user}/userCreatedCount/`,
  createReports: `${user}/createReports`,
  getUserDetails: `${user}/getUserDetails/`,
  userFollowingCount: `${user}/userFollowingCount/`,
  userFollowerCount: `${user}/userFollowerCount/`,
  userLikesCount: `${user}/userLikesCount/`,
  nftSoldCount: `${user}/nftSoldCount/`,
  followUnfollow: `${user}/followUnfollow/`,

  //nft
  ipfsUpload: `${nft}/ipfsUpload`,
  createNFT: `${nft}/createNFT`,
  uploadNFT: `${nft}/uploadNFT`,
  likeDislikeNft: `${nft}/likeDislikeNft/`,
  cancelOrder: `${order}/cancelOrder`,
  sendNFT: `${nft}/sendNFT`,
  showNftHistory: `${nft}/showNftHistory`,

  //order
  createOrder: `${order}/createOrder`,
  allListOrder: `${order}/allListOrder`,
  viewOrder: `${order}/viewOrder/`,
  editOrder: `${order}/editOrder`,
  buyOrder: `${order}/buyOrder`,
  likeDislikeOrder: `${order}/likeDislikeOrder/`,
  sendOrderToUser: `${order}/sendOrderToUser`,

  //collection
  createCollection: `${collection}/createCollection`,
  myCollectionList: `${collection}/myCollectionList`,
  viewCollection: `${collection}/viewCollection/`,
  collectionList: `${collection}/collectionList/`,

  //nfttopseller
  topSeller: `${newuser}/user/topSalers`,

  //collectionOrderList
  collectionOrderList: `${newuser}/order/particularCollectionOrderList`,

  //activity
  allListActivity: `${activity}/allListActivity`,
  listActivity: `${activity}/listActivity`,

  //activity
  createBid: `${bid}/createBid`,
  hotBid: `${bid}/hotBid`,
  showActivity: `${nft}/showActivity`,

  //notification
  readNotification: `${notification}/readNotification`,
}
export default apiConfig
