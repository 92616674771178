import React from "react";
import {
  Grid,
  Box,
  Link,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { FaDiscord, FaLinkedinIn, FaReddit } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineGooglePlus } from "react-icons/ai";
import TwitterIcon from "@material-ui/icons/Twitter";
import { AiOutlineInstagram } from "react-icons/ai";
import TelegramIcon from "@material-ui/icons/Telegram";
import InstagramIcon from "@material-ui/icons/Instagram";
import InputAdornment from "@material-ui/core/InputAdornment";

import AccountCircle from "@material-ui/icons/AccountCircle";
import { SiTiktok } from "react-icons/si";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "57px",
    // background:"linear-gradient(152.97deg, rgb(0 0 0 / 55%) 0%, rgb(0 0 0 / 62%) 100%)",
    background:
      "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
    filter: "drop-shadow(0px 0px 53px rgba(0, 0, 0, 0.25))",
    backdropFilter: "blur(42px)",
    "& h5": {
      fontSize: "20px",
      color: "#fff",
      fontFamily: "Rajdhani",
      marginBottom: "20px",
    },
    "& p": {
      fontSize: "12px",
      color: "#fff",
      margin: "0",
      padding: "13px 0 10px",
    },
    "& ul": {
      paddingLeft: "0",
      listStyle: "none",
      margin: "0",
      "& li": {
        marginBottom: "10px",
      },
    },
    "& input": {
      color: "#fff",
      background: "#3E3E3E",
      borderRadius: "5px",
      "&::placeholder": {
        color: "#fff",
      },
    },
    "& button": {
      margin: "0",
      minWidth: "100px",
      borderRadius: "5px",
      background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
      "&:hover": {
        background: "#000",
      },
    },
    "& .footer_text": {
      "& a": {
        color: "#848484",
        fontSize: "16px",
        fontWeight: "400",
        "&:hover": {
          color: "#fff",
          textDecoration: "none",
        },
      },
    },
    "& .follow": {
      display: "flex",
      "& a": {
        // background: "#3C3C3C",
        color: "#fff",
        marginRight: "5px",
        height: "30px",
        width: "30px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          color: "#f6c73b",
        },
      },
    },
  },
  subscribe: {
    background: "#F6C73B",
    borderRadius: "44px 0px 0px 44px",
  },
  btnend: {
    "& .MuiOutlinedInput-adornedEnd ": {
      paddingLeft: "8px",
    },
  },
  subscribBox: {
    position: "relative",
    "& input": {
      paddingRight: "110px",
    },
    "& button": {
      background: "#F6C73B",
      borderRadius: "44px 0px 0px 44px",
      height: "100%",
      position: "absolute",
      right: "0",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <Box className={classes.footerSection}>
      <Container maxWidth='lg'>
        <Grid container spacing={1} style={{dusplay:"flex", justifyContent:"space-between"}}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box display='flex' alignItems='center'>
              <Box>
                <img alt='' src='images/logo.png' height={60} />
              </Box>
            </Box>
            <Box align='left' mt={1} mb={1} className='follow'>
              <Link href='https://twitter.com/guilddaotoken' target='_blank'>
                <TwitterIcon />
              </Link>
              <Link href='https://t.me/guilddaotoken' target='_blank'>
                <TelegramIcon />
              </Link>
              <Link
                href='https://www.facebook.com/groups/1545893852463687'
                target='_blank'
              >
                <FaFacebookF />
              </Link>
              <Link
                href='https://www.instagram.com/guilddaotoken/?hl=en'
                target='_blank'
              >
                <InstagramIcon />
              </Link>
              <Link
                href='https://discord.com/channels/928841323593752616/928841323593752619'
                target='_blank'
              >
                <FaDiscord style={{ fontSize: 20 }} />
              </Link>
              <Link
                href='https://www.reddit.com/r/guilddaotoken/'
                target='_blank'
              >
                <FaReddit style={{ fontSize: 20 }} />
              </Link>
              <Link
                href='https://www.tiktok.com/@guilddaotoken'
                target='_blank'
              >
                <SiTiktok style={{ fontSize: 20 }} />
              </Link>
            </Box>
            <Box>
              <Typography>Copyright Guild Dao. All rights reserved</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} align='left'>
            <Box class=' col-lg-4 col-md-4 col-sm-6 col-12'>
              {/* <Typography variant='h5'>Contact</Typography> */}
              <ul className='footer_text'>
                <li>
                  <Link href='#'>Explore </Link>
                </li>
                {/* <li>
                  <Link href='/faq'>Help center</Link>
                </li> */}
                <li>
                  <Link href='/privacy-policy'>Privacy Policy</Link>
                </li>
              </ul>
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={4} lg={4} align='left'>
            <Typography variant='h5'>GET THE LATEST UPDATES</Typography>
            <Box className={classes.subscribBox}>
              <TextField
                style={{
                  backgroundColor: "#3C3C3C",
                }}
                id='outlined-basic'
                placeholder='Enter your Email'
                variant='outlined'
                fullWidth
              />
              <Button variant='contained' className={classes.subscribe}>
                I’m In
              </Button>
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
}
